import React from 'react';
import { connect } from 'react-redux';

import TextInput from 'presentational/formControls/TextInput';
import SelectInput from 'presentational/formControls/SelectInput';
import AudioPlayerInput from 'presentational/formControls/AudioPlayerInput';

const CallerQuestionForm = (props) => {

    const { form, updateForm } = props;
    const options = [];
    props.recordings.map((obj) =>
      options.push({ value: obj.name, label: obj.name }));

    let recordingUrl = `/api/v1/core/${props.recordings[0].tenant}/playrecording`;

    return (
      <div>
        <TextInput
          errorText={props.formErrors.question_name}
          field="question_name"
          placeholder="Question Name"
          onChange={(val) => updateForm('question_name', val)}
          value={form['question_name']}
        />
        <TextInput
          field="question_description"
          placeholder="Question Description"
          onChange={(val) => updateForm('question_description', val)}
          value={form['question_description']}
        />
        <SelectInput
          field="question_type"
          placeholder="Question Type"
          options={[{ value: 'dialpad', label: 'Dial Pad' }, { value: 'voice', label: 'Voice' }]}
          onChange={(val) => updateForm('question_type', val)}
          value={form['question_type']}
        />
        {props.form.question_type === "dialpad" ?
          <SelectInput
            errorText={props.formErrors.dialpad_min}
            field="dialpad_min"
            placeholder="Minimum Rating"
            options={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            onChange={(val) => updateForm('dialpad_min', val)}
            value={form['dialpad_min']}
          />
        : null}
        {props.form.question_type === "dialpad" ?
          <SelectInput
            errorText={props.formErrors.dialpad_max}
            field="dialpad_max"
            placeholder="Maximum Rating"
            options={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            onChange={(val) => updateForm('dialpad_max', val)}
            value={form['dialpad_max']}
          />
        : null}
        <AudioPlayerInput
          errorText={props.formErrors.question_pre_recording}
          field="question_pre_recording"
          onChange={(val) => updateForm('question_pre_recording', val)}
          placeholder="Pre-Question Recording"
          recordingUrl={recordingUrl}
          value={form['question_pre_recording']}
          options={options}
        />
        <AudioPlayerInput
          field="question_post_recording"
          onChange={(val) => updateForm('question_post_recording', val)}
          placeholder="Post-Question Recording"
          recordingUrl={recordingUrl}
          value={form['question_post_recording']}
          options={options}
        />
        <AudioPlayerInput
          field="question_invalid_recording"
          onChange={(val) => updateForm('question_invalid_recording', val)}
          placeholder="Invalid Question Recording"
          recordingUrl={recordingUrl}
          value={form['question_invalid_recording']}
          options={options}
        />
      </div>
    );
}

const mapStateToProps = (state) => ({
    recordings: state.recordings.data,
});

export default connect(mapStateToProps)(CallerQuestionForm);
