import React, { Component } from 'react';

import StandardSettingsPage from 'presentational/StandardSettingsPage';
import TextInput from 'presentational/formControls/TextInput';
import SubmitButton from 'presentational/formControls/SubmitButton';
import CancelButton from 'presentational/formControls/CancelButton';
import { errorNotification } from '../../actions/notifications';

class New extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorNameText: '',
            name: '',
        };
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {

      const { pendingRequest } = this.props;
      const prevPending = prevProps.pendingRequest.pending;
      const currentPending = pendingRequest.pending;

      // No changes in pending status or we just started the request
      if (prevPending === currentPending) return;
      if (!prevPending && currentPending) return;

      // Handle request failure
      if (pendingRequest.failure) {
        this.setState({ disabled: false });
        errorNotification({
          title: 'Failed!',
          message: pendingRequest.failure,
        });
        return;
      }
      
      this.props.selectPostCallSurvey(this.state.name);
    }

    async submitForm() {
        if (!this.isFormValid()) return;

        this.setState({ disabled: true });
        this.props.submitForm(this.state.name);
    }

    isFormValid() {
      const { name } = this.state;
  
      const isNameError = name.trim().length === 0 || !/^[a-z0-9]+$/i.test(name);
  
      this.setState({
        errorNameText: isNameError ? "The name is required that has no special characters other than letters and numbers." : '',
      })
  
      return !isNameError;
    }

    render() {

        const style = {
            header: {
                color: '#595959',
            },

        };

        return (
          <div style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
          }}>
          <StandardSettingsPage>
            <h2 style={style.header}>Enter Post Call Survey Name</h2>
            <TextInput
              errorText={this.state.errorNameText}
              field="name"
              onChange={(name) => this.setState({ name, errorNameText: '' })}
              placeholder="Name"
              value={this.state.name}
              style={{width: '35em'}}
            />
            <div>
              <SubmitButton
                disabled={this.state.disabled}
                label="Create"
                onClick={() => this.submitForm(this.state.name)}
              />
              <CancelButton
                label="Cancel"
                onClick={() => this.props.selectPostCallSurvey('')}
              />
            </div>
          </StandardSettingsPage>
          </div>
        );
    }
}

export default New;
