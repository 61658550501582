import { combineReducers } from 'redux';

import { 
    SELECT_POST_CALL_SURVEY,
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA, 
} from '../actions/typeConstants';

const id = 'name';

const auditHistory = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;


    case 'POSTCALLSURVEYS_HTTP_SUCCESS':

        if (!action.auditHistory) {
            return state;
        }

        // If this is for an object, return
        if (action.reqObject) {
            return state;
        }

        return Object.assign([], state, action.data);
    default:
        return state;
    }
};

const data = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'POSTCALLSURVEYS_HTTP_SUCCESS':

        if (action.auditHistory) {

            const newState = Object.assign([], state);

            const found = state.findIndex(obj => obj[id] == action.reqObject);
            if (found === -1 || !newState[found]) {
                return state;
            }

            newState[found].auditHistory = action.data;

            return Object.assign([], newState);
        }

        if (action.reqObject || action.data?.data?.name) {
            const name = action.reqObject || action.data.data.name;
            const newState = Object.assign([], state);
            const found = state.findIndex(obj => obj && obj[id] === name);

            if (!action.data) {
                delete newState[found];
            } else if (found === -1) {
                newState.push(action.data.data);
            } else {
                newState[found] = action.data.data;
            }

            return Object.assign([], newState);
        }

        return Object.assign([], state, action.data.data);
    default:
        return state;
    }
};

const metaData = (state = null, action) => {
    

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;


    case 'POSTCALLSURVEYS_HTTP_SUCCESS':

        if (!action.data || !action.data.metaData || action.reqObect) {
            return state;
        }

        return action.data.metaData;
    default:
        return state;
    }
};


const pendingRequest = (state = { pending: false, failure: "" }, action) => {
    switch (action.type) {
        case 'POSTCALLSURVEYS_HTTP_PENDING':
            return {
                pending: true,
                failure: ""  // Reset the error when a request is pending
            };
        case 'POSTCALLSURVEYS_HTTP_FAILURE':

            // Check if there's an error message in the HTTP response body
            if(action.res?.body?.error?.message?.trim().length > 0) {
                return {
                    pending: false,
                    failure: action.res.body.error.message,
                };
            }

            // Check if there's an error message directly in the action error
            if (action.error?.message?.trim().length > 0) {
                return {
                    pending: false,
                    failure: action.error.message,
                };
            }

            // If no error messages are found, set a generic failure message
            return {
                pending: false,
                failure: "Something went wrong. Please try again later.",
            };
        case 'POSTCALLSURVEYS_HTTP_SUCCESS':
            return {
                pending: false,
                failure: ""  // Clear any errors on success
            };
        default:
            return state;
    }
};

const selected = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case SELECT_POST_CALL_SURVEY:
        return action.data;

    default:
        return state;
    }
};

export const postCallSurveys = combineReducers({
    auditHistory,
    data,
    metaData,
    pendingRequest,
    selected,
});
