import React, { Component } from 'react';

import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SubmitButton from 'presentational/formControls/SubmitButton';
import CancelButton from 'presentational/formControls/CancelButton';
import FormControlCard from 'presentational/FormControlCard';
import EditIcon from '@material-ui/icons/Edit';


class New extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorNameText: '',
            errorFilterTypeText: '',
            form: {},
        };
    }

    async submitFullForm() {

     if (!this.isFormValid()) return;

     this.setState({ disabled: true });
     const error = await this.props.submitForm(this.state.form);
        if (error) {

            console.error(error);
            this.setState({
                disabled: false,
                errorText: error.summary,
            });
            return;
        }

        this.props.selectCallFilter(this.state.form.name);
    }

    submitForm(field, data){
      const { form } = this.state
      form[field] = data;
      this.setState({form})
    }
    
    isFormValid() {
      const name = this.state.form['name'];
      const filterType = this.state.form['filterType'];
      
      const isNameError = name.trim().length === 0 || !/^[a-z0-9]+$/i.test(name);
      const isFilterTypeError = !filterType || filterType.trim().length === 0;
      
      this.setState({
        errorNameText: isNameError ? "The name is required and has no special characters other than letters, numbers." : '',
        errorFilterTypeText: isFilterTypeError ? "The filter type is required" : '',
      })
      
      return !isNameError && !isFilterTypeError;
    }

    render() {
      const filterOptions = [{value: "number", label:"Phone Number"}, {value: "areacode", label:"Area Code"}];

        const style = {
            header: {
                color: '#595959',
            },

        };

        return (
          <StandardSettingsPage>
            <h2 style={style.header}>New Call Filter</h2>
            <FormControlCard
              formControl="TextInput"
              icon={<EditIcon />}
              title="Enter Incoming Call Filter Name"
              subtitle="Name"
              placeholder= "Name"
              field="name"
              form={this.state.form}
              submitForm={(field, data) => this.submitForm(field, data)}
              errorText = {this.state.errorNameText}
            />
            <FormControlCard
              formControl="SelectInput"
              icon={<EditIcon />}
              title="Filter Type"
              subtitle="Filter call by phone number or area code"
              placeholder = "Filter Type"
              field="filterType"
              form={this.state.form}
              submitForm={(field, data) => this.submitForm(field, data)}
              options={filterOptions}
              errorText = {this.state.errorFilterTypeText}
            />
            <div>
              <SubmitButton
                disabled={this.state.disabled}
                label="Create"
                onClick={() => this.submitFullForm()}
              />
              <CancelButton
                label="Cancel"
                onClick={() => this.props.handleCancelClick()}
              />
            </div>
          </StandardSettingsPage>
        );
    }
}

export default New;
