import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import ErrorCard from 'presentational/ErrorCard';
import Loading from 'presentational/Loading';
import NewCardPlaceholder from 'presentational/NewCardPlaceholder';
import StandardSettingsPage from 'presentational/StandardSettingsPage';

import QuestionCard from './questionCard';
import NewCallerQuestionForm from './newCallerQuestionForm'

import { getCoreHttp } from 'actions/httpRequest';

const styles = () => ({
    button: {
        fontSize: '100%',
    },
});

class CallerQuestions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: null,
            submitError: null,
            showingModal: false,
            mediaQuery: false,
            newCallerQuestionForm: {
                question_description: '',
                question_name: '',
                question_number: 1,
                question_type: 'dialpad',
                dialpad_min: '1',
                dialpad_max: '5',
                question_invalid_recording: '',
                question_post_recording: '',
                question_pre_recording: '',
            },
            formErrors: {
                question_name: '',
                dialpad_max: '',
                dialpad_min: '',
                question_pre_recording: '',
            },
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

        // If we don't have data, lets return
        if (!this.props.data) {
            return;
        }

        this.setState({ form: this.props.data });
    }

    componentWillReceiveProps(nextProps) {

        // If we don't have data, lets return
        if (!nextProps.data) {
            return;
        }

        this.setState({ form: nextProps.data });
    }

    async submitForm(submittedForm) {

        const form = submittedForm || this.state.form;

        console.log('SUBMITTING FORM: ', form);

        // If no error, lets set the data
        // on the form and set it to state without errors
        this.setState({
            form,
            errors: {},
        });

        const submitError = await this.props.submitForm(form);

        if (submitError) {
            this.setState({ submitError });
        } else {
            this.setState({ submitError: null });
        }
    }

    updateNewCallerQuestionForm(field, value) {

        const { newCallerQuestionForm } = this.state;

        newCallerQuestionForm[field] = value;

        this.setState({ newCallerQuestionForm });
    }

    removeQuestion(id) {

        console.log('Removing question for caller');

        const foundIndex = this.state.form.questions.call.findIndex(
            (obj) => obj && obj.question_id === id,
        );

        this.state.form.questions.call.splice(foundIndex, 1);
        this.submitForm(this.state.form);
    }

    renderQuestionCard(question, index) {

        return (
            <QuestionCard
              form={this.state.form}
              context="call"
              data={question}
              key={index + question.question_id}
              id={question.question_id}
              submitForm={(form) => this.submitForm(form)}
              removeQuestion={(question_id) => this.removeQuestion(question_id)}
            />
        );
    }

    handleClose(submit) {

        if (submit) {

            const { newCallerQuestionForm, form } = this.state;

            this.setState({ formErrors: {} })

            const formErrors = this.validateForm();

            if (Object.keys(formErrors).some(err => formErrors[err])) {

                this.setState({ formErrors })
                return;
            }

            const questionNumbers = [];

            form.questions['call'].map(question => questionNumbers.push(parseInt(question.question_number)));

            if(questionNumbers.length > 0){
                newCallerQuestionForm.question_number = Math.max(...questionNumbers) + 1;
            } else {
                newCallerQuestionForm.question_number = 1;
            }

            this.setState({ formErrors: { name: '' } });

            if (!this.props.data.name) {

                this.setState({ formErrors: { name: 'No name for survey, please try again.' } });
                return;
            }
            form.questions['call'].push(newCallerQuestionForm);

            this.setState({ form });
            this.submitForm(form);
        }

        // Reset state
        this.setState({
            showingModal: false,
            newCallerQuestionForm: {
                question_description: '',
                question_name: '',
                question_number: 1,
                question_type: 'dialpad',
                dialpad_min: '1',
                dialpad_max: '5',
                question_invalid_recording: '',
                question_post_recording: '',
                question_pre_recording: '',
            },
        });
    }

    validateForm() {
            const { newCallerQuestionForm } = this.state;
            
            const max = parseInt(newCallerQuestionForm['dialpad_max']);
            const min = parseInt(newCallerQuestionForm['dialpad_min']);
            let formErrors = {};
            if (min >= max) {
                formErrors['dialpad_max'] = 'Maximum rating must be higher than minimum rating';
            }
            
            const questionName = newCallerQuestionForm['question_name'].trim();
            if (!questionName || questionName.length === 0) {
                formErrors['question_name'] = 'Question name is required';
            }
            
            const questionPreRecording = newCallerQuestionForm['question_pre_recording'];
            if (!questionPreRecording || questionPreRecording.length === 0) {
                formErrors['question_pre_recording'] = 'Pre-Question recording is required';
            }
            
            return formErrors;
        }

    render() {

        if (!this.props.data || !this.props.data.questions || !this.props.recordings || !this.state.form) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }


        const { form } = this.state;
        const { classes } = this.props;
        const minWidth = this.state.mediaQuery.matches;

        return (
          <StandardSettingsPage>

            <NewCardPlaceholder
              title="Add new question for caller"
              onClick={() => this.setState({ showingModal: true })}
            />

            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }

            {form.questions.call.map((question, i) => {
                return this.renderQuestionCard(question, i);
            })}

            <Dialog
              open={this.state.showingModal}
              onClose={() => this.handleClose(false)}
              fullWidth={true}
            >
              <DialogTitle>Add new question for caller</DialogTitle>
              <DialogContent>
                <NewCallerQuestionForm
                    // usedSkills={form}
                    form={this.state.newCallerQuestionForm}
                    formErrors={this.state.formErrors}
                    updateForm={(field, value) => this.updateNewCallerQuestionForm(field, value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                    classes={{ root: classes.button }}
                    color='primary'
                    onClick={() => this.handleClose(false)}
                >Cancel</Button>
                <Button
                    classes={{ root: classes.button }}
                    color='primary'
                    onClick={() => this.handleClose(true)}
                >Submit</Button>
              </DialogActions>
            </Dialog>
          </StandardSettingsPage>
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
    recordings: state.recordings.data,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(CallerQuestions));
