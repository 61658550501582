import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

import ErrorCard from 'presentational/ErrorCard';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';


import AddIcon from '@material-ui/icons/AddBox';
import CheckIcon from '@material-ui/icons/CheckCircle';
import RecordIcon from '@material-ui/icons/RecordVoiceOver';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import PersonIcon from '@material-ui/icons/PersonOutline';
import RingIcon from '@material-ui/icons/NotificationsActive';
import EditIcon from '@material-ui/icons/BorderColor';
import RingingIcon from '@material-ui/icons/RingVolume';
import CallIcon from '@material-ui/icons/Call';
import GroupIcon from '@material-ui/icons/Group';
import FilterIcon from '@material-ui/icons/FilterList';
import MusicIcon from '@material-ui/icons/MusicVideo';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

import { keySrt } from 'utils/misc';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            disabled: true,
            form: {},
            rollbackForm: {},
            submitError: null,
            newChanges: false,
        };
    }

    componentDidMount() {
        this.setState({ form: this.props.data }, ()=> this.createRollbackForm());
        document.addEventListener('mousedown', this.handleClick, false)
    }

     componentWillUnmount(){
       document.removeEventListener('mousedown', this.handleClick, false)
      }

    createRollbackForm() {
      const rollbackForm = Object.assign({}, this.state.form);
      this.setState({ rollbackForm });
    }

    handleClick = (e) => {
      if (this.node) {
        if (this.node.contains(e.target)) {
          this.props.clickedNode();
        }
      }
    }

    async submitForm(field, data) {
        if (this.state.rollbackForm[field] === data) {
            console.log('No update');
            return;
        } else {
          this.setState({
            newChanges: true,
            disabled: false}, ()=>
          this.props.madeChanges(this.state.newChanges, this.state.form))
        }

        if (this.state.disabled == true) {
            this.setState({disabled: false});
        }

        const { form } = this.state;

        // Lets validate our field
        const error = this.validateField(field, data);
        if (error) {

            this.setState({
                errors: { [field]: error },
            });

            errorNotification({ message: 'Please fix errors on form' });
            return;
        }

        // If no error, lets set the data
        // on the form and set it to state without errors
        form[field] = data;
        this.setState({
            form,
            errors: { [field]: '' }
        });
    }

    validateField(field, data) {

        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {

        case 'password':

            // We only want to allow numbers
            if (data.match(/[^0-9]/)) {
                return 'Please only use digits 0-9 for password';
            }

        default:
            return null;
        }
    }

    submitFullForm(cancel){
      if(cancel){
        this.props.cancel();
      } else{
        this.props.submitForm(this.state.form);
      }
    }

    render() {

        if (!this.props.data || !this.props.data.config || !this.state.form) {
            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>
            );
        }

        let ringoptions = this.props.data && this.props.data.config && this.props.data.config.distinctivering ? ["No Distinctive Ring", ...this.props.data.config.distinctivering] : ["No Distinctive Ring"];
        let recordoptions = ["On Demand", "Never", "Always"]
        let strategyoptions = ["ringall", "sequential"];
        const defaultValue = " ";
        let holdmusicoptions = [{ label: "Not Selected", value: defaultValue}];
        if(this.props.data && this.props.data.config && this.props.data.config.musiconhold){
          this.props.data.config.musiconhold.map(option => 
            holdmusicoptions.push({label:option.name.split('-')[0], value: option.name}))
        }

        const ringsetting = this.state.form.distinctivering ? { distinctivering: this.state.form.distinctivering } : { distinctivering: "No Distinctive Ring" };

        return (
          <div ref={node => this.node = node} style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',}}>
            <StandardSettingsPage >
              {this.state.submitError ?
                <ErrorCard data={this.state.submitError} />
                : null
              }
              <FormControlCard
                form={this.state.form}
                field="description"
                formControl="TextInput"
                title="Call Group Description"
                subtitle="Edit the call description here"
                icon={<EditIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
              />
              <FormControlCard
                form={this.state.form}
                field="extension"
                formControl="TextInput"
                title="Call Group Extension Number"
                subtitle="Filling this field in with a numeric extension will allow you to dial using the entered extension from any phone on your account."
                icon={<PersonIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
              />
              <FormControlCard
                form={ringsetting}
                field="distinctivering"
                formControl="SelectInput"
                title="Distinctive Ring"
                subtitle="Set a distinctive ring on a handset for this call group. This requires a supported phone from Polycom or a Yealink.  This will always default to none unless you select otherwise."
                icon={<RingIcon />}
                submitForm={(field, data) => this.submitForm(field, data === "No Distinctive Ring" ? "" : data)}
                options={ringoptions}
              />
              <FormControlCard
                form={this.state.form}
                field="ringinuse"
                formControl="ToggleInput"
                title="Ring in use"
                subtitle="If set to Yes, Call Group members phones will ring, even if the member is already on a phone call."
                icon={<RingingIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                label="No / Yes"
              />
              <FormControlCard
                form={this.state.form}
                field="confirm_call"
                formControl="ToggleInput"
                title="Confirm All Calls By Pressing 1"
                subtitle="If enabled, this setting will force the answering member to press the 1 digit on their keypad before the call is connected."
                icon={<CheckIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                label="No / Yes"
              />
              <FormControlCard
                form={this.state.form}
                field="confirm_external_member"
                formControl="ToggleInput"
                title="Confirm External Call Group Members"
                subtitle="If enabled, this setting requires all external numbers used in the Call Group as members to press 1 before speaking to caller. This prevents the call from being answered by the external call group member's voicemail box."
                icon={<GroupIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                label="No / Yes"
              />
              <FormControlCard
                form={this.state.form}
                field="pass_did_to_external"
                formControl="ToggleInput"
                title="Send Caller Dialed Number Instead of Caller ID"
                subtitle="If enabled, this setting will send the external Caller ID as the caller's dialed number, rather than the calling party's Caller ID."
                icon={<CallIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                label="No / Yes"
              />
              <FormControlCard
                form={this.state.form}
                field="mark_answered_elsewhere"
                formControl="ToggleInput"
                title="Send Answered Elsewhere to Cancelled Calls"
                subtitle="Sending the SIP Answered Elsewhere message to handsets will generally cause the 'Missed Call' counter not to increment or display on the phone."
                icon={<RemoveIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                label="No / Yes"
              />
              <FormControlCard
                form={this.state.form}
                field="callrecording"
                formControl="SelectInput"
                title="Record Calls"
                subtitle="Set a distinctive ring on a handset for this call group. This requires a supported phone from Polycom or a Yealink.  This will always default to none unless you select otherwise."
                icon={<RecordIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                options={recordoptions}
              />
              <FormControlCard
                form={this.state.form}
                field="prependedcid"
                formControl="TextInput"
                title="Prepend Text to Caller ID"
                subtitle="Adding text to this field will prepend it to the Caller ID Name on the phone when it rings the extension. This can be useful if you need to distinguish between multiple types of callers."
                icon={<AddIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
              />
              <FormControlCard
                form={this.state.form}
                field="strategy"
                formControl="SelectInput"
                title="Ring Strategy"
                subtitle="You may have the phones ring all at once, which is the ringall option. The phones may also be rang sequentially, which will ring the members from the top of the list to the bottom for the duration specificed."
                icon={<FilterIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                options={strategyoptions}
              />
              <FormControlCard
                form={this.state.form}
                field="musiconhold_caller"
                formControl="SelectInput"
                title="Music On Hold Category"
                subtitle="This catetory determines the Music On Hold selection a caller will hear when the answering extension places them on hold."
                icon={<MusicIcon />}
                submitForm={(field, data) => this.submitForm(field, data === defaultValue ? "" : data)}
                options={holdmusicoptions}
              />
              <FormControlCard
                form={this.state.form}
                field="musiconhold"
                formControl="SelectInput"
                title="Play Music to Caller Before Answer"
                subtitle="Before the initial answer of the call, this option will allow enabling the playing of Music on Hold to the caller instead of ringing."
                icon={<MusicIcon />}
                submitForm={(field, data) => this.submitForm(field, data === defaultValue ? "" : data)}
                options={holdmusicoptions}
              />
              <Footer
                disabled={this.state.disabled}
                onClick={(cancel) => this.submitFullForm(cancel)}
              />
            </StandardSettingsPage>
          </div>
        );
    }
}

const bindActions = (dispatch) => ({});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, bindActions)(Settings);
