import React from 'react';
import { connect } from 'react-redux';

import TextInput from 'presentational/formControls/TextInput';
import SelectInput from 'presentational/formControls/SelectInput';

const CallerQuestionForm = (props) => {

    const { form, updateForm } = props;

    return (
      <div>
        <TextInput
          errorText={props.formErrors.question_name}
          field="question_name"
          placeholder="Question Name"
          onChange={(val) => updateForm('question_name', val)}
          value={form['question_name']}
        />
        <TextInput
          field="question_description"
          placeholder="Question Description"
          onChange={(val) => updateForm('question_description', val)}
          value={form['question_description']}
        />
        <SelectInput
          // errorText={props.formErrors.question_type}
          field="question_type"
          placeholder="Question Type"
          options={[{ value: 'numerical', label: 'Numerical' }, { value: 'text', label: 'Text Input' }]}
          onChange={(val) => updateForm('question_type', val)}
          value={form['question_type']}
        />
        <TextInput
          field="question_text"
          placeholder="Question Text"
          onChange={(val) => updateForm('question_text', val)}
          value={form['question_text']}
        />
        {props.form.question_type === "numerical" ?
          <SelectInput
            errorText={props.formErrors.rating_min}
            field="rating_min"
            placeholder="Minimum Rating"
            options={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']}
            onChange={(val) => updateForm('rating_min', val)}
            value={form['rating_min']}
          />
        : null}
        {props.form.question_type === "numerical" ?
          <SelectInput
            errorText={props.formErrors.rating_max}
            field="rating_max"
            placeholder="Maximum Rating"
            options={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']}
            onChange={(val) => updateForm('rating_max', val)}
            value={form['rating_max']}
          />
        : null}
      </div>
    );
}

const mapStateToProps = (state) => ({
    recordings: state.recordings.data,
});

export default connect(mapStateToProps)(CallerQuestionForm);
